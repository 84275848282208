import React from "react";

import { Link } from "gatsby";
import dateFormat from "dateformat"

import PerformancesTable from "../../components/performances/performances-table";

class PerformancesList extends React.Component {
  constructor(props) {
    super(props);

    this.groupPerformancesByYear = this.groupPerformancesByYear.bind(this);
    this.groupPerformancesByMonth = this.groupPerformancesByMonth.bind(this);
  }

  groupPerformancesByMonth(performances) {
    const months = performances.map((performance) => {
      return performance.node.dates[0].substring(5, 7);
    }).filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    return months.map((month) => {
      return performances.filter((performance) => {
        return performance.node.dates[0].substring(5, 7) === month;
      });
    });
  }

  groupPerformancesByYear(performances) {
    const years = performances.map((performance) => {
      return performance.node.dates[0].substring(0, 4);
    }).filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    return years.map((year) => {
      return performances.filter((performance) => {
        return performance.node.dates[0].substring(0, 4) === year;
      });
    });
  }

  render() {
    if (this.props.grouping === 'year') {
        const performancesByYear = this.groupPerformancesByYear(this.props.performances.edges);

        return (performancesByYear && performancesByYear.length > 0) ? (
          <div>
            {performancesByYear.map((performances, idx) => {
              const year = performances[0].node.dates[0].substring(0, 4);

              return (
                <div key={idx}>
                  <h3 className="performances-table__header">
                    <Link to={`/performances/years/${year}`}>{year}</Link>
                  </h3>
                  <PerformancesTable performances={performances}
                                    artists={this.props.artists}
                                    venues={this.props.venues} />
                </div>
              )
            })}
          </div>
        ) : null;
    }

    if (this.props.grouping === 'month') {
      const performancesByMonth = this.groupPerformancesByMonth(this.props.performances.edges);
      
      return (performancesByMonth && performancesByMonth.length > 0) ? (
        <div>
          {performancesByMonth.map((performances) => {
            const performanceDate = new Date(performances[0].node.dates[0]);
            let monthHeader = '';

            try {
              monthHeader = dateFormat(performanceDate, "mmmm yyyy");
            } catch (e) {
              monthHeader = null;
            }

            return (
              <div>
                <h3 className="performances-table__header">
                  {monthHeader} 
                </h3>
                <PerformancesTable performances={performances}
                                   artists={this.props.artists}
                                   venues={this.props.venues} />
              </div>
            )
          })}
        </div>
      ) : null;
    }

    return (
      <PerformancesTable performances={this.props.performances.edges}
                         artists={this.props.artists}
                         venues={this.props.venues} />
    );
  }
}

export default PerformancesList;