import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/layout";

import Helmet from "react-helmet";

import FilterBar from "../../components/browser/filter-bar";
import PageContainer from "../../components/layout/page-container";
import PageSection from "../../components/layout/page-section";
import PerformancesList from "../../components/performances/performances-list";

class PerformancesForYearPage extends React.Component {
  constructor({ data, pageContext }) {
    super();

    this.data = data;
    this.pageContext = pageContext;
  }

  render() {
    const performances = (this.data.allPerformancesJson) ? this.data.allPerformancesJson : null;

    return (
      <Layout>
        <PageContainer
          bottomPadding="lg"
        >
          <Helmet>
            <title>{`${this.pageContext.slug} – Year`}</title>
          </Helmet>

          <PageSection background="dark-accent">
            <h1 className="page-title">Performances from {this.pageContext.slug}</h1>
          </PageSection>

          <PageSection topPadding="md">
            {(performances) ? (
              <PerformancesList performances={this.data.allPerformancesJson} 
                                artists={this.data.allArtistsJson}
                                venues={this.data.allVenuesJson}
                                grouping='month' />
            ) : null}
            <FilterBar />
          </PageSection>
        </PageContainer>
      </Layout>
    )
  }
}

export default PerformancesForYearPage;

export const query = graphql`
  query PerformancesForYearQuery($startDate: Date!, $endDate: Date!) {
    allPerformancesJson(
      filter: 
        {dates: {gte: $startDate, lte: $endDate}, reviewed: {eq: true}},
      sort: {fields: [dates], order: ASC}) {
      edges {
        node {
          dates
          artists
          description
          venue
        }
      }
    },
    allArtistsJson {
      edges {
        node {
          reference
          canonical_name
        }
      }
    },
    allVenuesJson {
      edges {
        node {
          reference
          canonical_name
        }
      }
    }
  }
`;
