import React from "react";

import PerformanceRow from "../../components/performances/performance-row";

class PerformancesTable extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    const performances = this.props.performances;
    
    if (performances && performances.length > 0) {
      return (
        <table className="performances-table">
          <tbody>
            {performances.map((performance, idx) => {
              let firstForDate = true;
              let lastForDate = true;

              if ((idx > 0) && 
                  (performances[idx - 1].node.dates[0] === performances[idx].node.dates[0])) {
                firstForDate = false;
              }
              if ((idx < performances.length - 1) &&
                  (performances[idx].node.dates[0] === performances[idx + 1].node.dates[0])) {
                lastForDate = false;
              }
              
              return (
                <PerformanceRow performance={performance.node} 
                                firstForDate={firstForDate}
                                lastForDate={lastForDate}
                                artists={this.props.artists} 
                                venues={this.props.venues}
                                key={idx} />
              );
            })}
          </tbody>
        </table>
      )
    }

    return (
      <p>
        No performances for this year.
      </p>
    )
  }
}

export default PerformancesTable;