import React from "react";
import { Link } from "gatsby";

import dateFormat from "dateformat";

class PerformanceRow extends React.Component {
  constructor(props) {
    super(props);

    this.buildArtistLinks = this.buildArtistLinks.bind(this);
    this.buildVenueLink = this.buildVenueLink.bind(this);
  }

  formattedDates() {
    if (this.props.performance.dates) {
      let formattedDate = null;
      const performanceDate = new Date(this.props.performance.dates[0]);

      try {
        formattedDate = dateFormat(performanceDate, "mmm d");
      } catch (e) {
        formattedDate = null;
      }

      return (
        <div>{formattedDate}</div>
      );
    }

    return null;
  }

  buildArtistLinks(artistReferences) {
    const artists = artistReferences.map(reference => {
      return this.props.artists.edges.filter(({ node }) => reference === node.reference)[0];
    });
    
    return (
      <ul>
        {artists.map(({ node }, idx) => (
          <li>
            <Link key={idx} to={`/artists/${node.reference}`}>
              <span className="performances-table__label">{node.canonical_name}</span>
            </Link>
          </li>
        ))}
      </ul>
    );
  }

  buildVenueLink(venueReference) {
    const venue = this.props.venues.edges.filter((venue) => {
      return (venue.node.reference === venueReference)})[0];
    const venueName = (venue) ? venue.node.canonical_name : venueReference;
    
    return (
      <Link to={`/venues/${venueReference}`}>
        <span className="performances-table__label">{venueName}</span>
      </Link>
    );
  }

  render() {
    const description = (this.props.performance.description) ? (
      <p class="description">{this.props.performance.description}</p>
    ) : null;

    let rowClasses = '';
    rowClasses += (this.props.firstForDate) ? 'performances-table__first-date ' : '';
    rowClasses += (this.props.lastForDate) ? 'performances-table__last-date ' : '';

    return (
      <tr className={rowClasses}>
        <td className="performances-table__cell performances-table__date">
          {(this.props.firstForDate) ? 
            (<span className="performances-table__date-tag">{this.formattedDates()}</span>)
            : null}
        </td>
        <td className="performances-table__cell performances-table__artist">
          {description}
          {this.buildArtistLinks(this.props.performance.artists)}
        </td>
        <td className="performances-table__cell performances-table__venue">
          {this.buildVenueLink(this.props.performance.venue)}
        </td>
      </tr>
    )
  }
}

export default PerformanceRow;